import { Memoize } from "typescript-memoize";

const NOTE_CLASS_NAMES = [
  "A",
  "A♯",
  "B",
  "C",
  "C♯",
  "D",
  "D♯",
  "E",
  "F",
  "F♯",
  "G",
  "G♯",
] as const;
const ROOT_NOTE_CLASS_NAME = "A";
const ROOT_NOTE_FREQUENCY = 440;
const ROOT_NOTE_OCTAVE = 4;

export default class Note {
  readonly noteClass: number;
  readonly octave: number;

  constructor(noteClass: number, octave: number) {
    this.noteClass = mod(noteClass, NOTES_PER_OCTAVE);
    this.octave = octave + Math.floor(noteClass / NOTES_PER_OCTAVE);
  }

  static fromName(name: string): Note {
    const [, noteClassName, rawOctave, rawCents] = /([^\d]+)(-?\d+)(?:([-+]\d+)¢)?/.exec(name) ?? [];
    const noteClass = NOTE_CLASS_BY_NAME[noteClassName] + parseInt(rawCents ?? 0) / 100;
    const octave = parseInt(rawOctave);
    return new Note(
      noteClass,
      octave,
    );
  }

  static fromOrdinal(ordinal: number): Note {
    return new Note(
      ordinal + ORDINAL_OFFSET,
      0,
    );
  }

  static fromFrequency(frequency: number): Note {
    return this.fromOrdinal(Math.log2(frequency / ROOT_NOTE_FREQUENCY) * NOTES_PER_OCTAVE);
  }

  nearest(): Note {
    return new Note(
      Math.round(this.noteClass),
      this.octave
    );
  }

  next(): Note {
    let nextWholeNoteClass = Math.ceil(this.noteClass);
    if (this.noteClass === nextWholeNoteClass) {
      nextWholeNoteClass += 1;
    }
    return new Note(
      nextWholeNoteClass,
      this.octave,
    );
  }

  *getHarmonics(): Generator<Note> {
    let fundamentalFrequency = this.frequency;
    let frequency = fundamentalFrequency;
    while (true) {
      yield Note.fromFrequency(frequency);
      frequency += fundamentalFrequency;
    }
  }

  @Memoize()
  get name(): string {
    const nearestNote = this.nearest();
    const ordinalDifference = this.ordinal - nearestNote.ordinal;
    const centsSuffix = ordinalDifference !== 0 ?
      `${ordinalDifference > 0 ? '+' : ''}${Math.round(ordinalDifference * 100)}¢` :
      '';
    return `${NOTE_CLASS_NAMES[nearestNote.noteClass]}${nearestNote.octave}${centsSuffix}`;
  }

  @Memoize()
  get ordinal(): number {
    return NOTES_PER_OCTAVE * this.octave + this.noteClass - ORDINAL_OFFSET;
  }

  @Memoize()
  get frequency(): number {
    return ROOT_NOTE_FREQUENCY * Math.pow(2, this.ordinal / NOTES_PER_OCTAVE);
  }
}

function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

// Computed constants

const NOTE_CLASS_BY_NAME = Object.fromEntries(
  Object.entries(NOTE_CLASS_NAMES).map(([noteClass, noteName]) => [noteName, parseInt(noteClass)])
);
const NOTES_PER_OCTAVE = NOTE_CLASS_NAMES.length;
const ROOT_NOTE_CLASS = NOTE_CLASS_BY_NAME[ROOT_NOTE_CLASS_NAME];
const ORDINAL_OFFSET = ROOT_NOTE_OCTAVE * NOTES_PER_OCTAVE + ROOT_NOTE_CLASS;
