import "./App.css";

import react from "react";
import * as recharts from "recharts";

import Note from "./Note";
import Player from "./Player";

const NYQUIST_FREQUENCY = 22050;

function *getHarmonics(fundamental: Note, maxFrequency: number): Generator<Note> {
  for (const note of fundamental.getHarmonics()) {
    if (note.frequency > maxFrequency) {
      break;
    }
    yield note;
  }
}

function *getChromaticScale(startNoteName: string, endNoteName: string): Generator<Note> {
  let note = Note.fromName(startNoteName);
  const endNote = Note.fromName(endNoteName);
  while (note.ordinal <= endNote.ordinal) {
    yield note;
    note = note.next();
  }
}

function formatFrequency(frequency: number) {
  return frequency > 1000 ? `${(frequency / 1000).toFixed(1)}k` : frequency.toFixed(0);
}

function NoteTooltip(event: { player: Player, fundamental: Note | null } & any) {
  const note = event.payload?.[0]?.payload;
  if (note !== undefined) {
    event.player.play(note);
    return (
      <div className="Tooltip">
        {note.name}: {formatFrequency(note.frequency)}Hz
      </div>
    );
  }
  event.player.stop();
  return null;
};

function Chart({ notes, player }: { notes: Note[], player: Player }) {
  const [fundamental, setFundamental] = react.useState<Note | null>(null);
  const [harmonics, setHarmonics] = react.useState<Note[]>([]);

  return (
    <div className="Chart">
      <recharts.ResponsiveContainer>
        <recharts.LineChart
          margin={{
            top: 30,
            right: 30,
            bottom: 30,
            left: 50,
          }}
          data={notes}
          onClick={
            (event) => {
              if (event === null) {
                setFundamental(null);
                setHarmonics([]);
                return;
              }
              const note = event.activePayload?.[0].payload;
              if (note === undefined) {
                setFundamental(null);
                setHarmonics([]);
                return;
              }
              setFundamental(note);
              setHarmonics([...getHarmonics(note, NYQUIST_FREQUENCY)]);
            }
          }
        >
          <recharts.CartesianGrid vertical={false} horizontal={false} />
          <recharts.XAxis type="number" dataKey="ordinal" tickFormatter={
            (ordinal) => Note.fromOrdinal(ordinal).nearest().name
          } tickCount={10} />
          <recharts.YAxis type="number" unit="Hz" tickFormatter={formatFrequency} tickCount={999} />
          {harmonics.length > 0 ?
            harmonics.map(
              (harmonic) => <recharts.ReferenceLine
                key={harmonic.frequency}
                y={harmonic.frequency}
                stroke="#d88884"
              />
            ) :
            null
          }
          <recharts.Line name="Frequency" unit="Hz" type="step" isAnimationActive={false} dataKey="frequency" fill="#8884d8" />
          <recharts.Tooltip content={<NoteTooltip player={player} fundamental={fundamental} />} isAnimationActive={false} />
          <recharts.Brush dataKey="ordinal" tickFormatter={
            (ordinal) => Note.fromOrdinal(ordinal).nearest().name
          }/>
        </recharts.LineChart>
      </recharts.ResponsiveContainer>
    </div>
  );
}

function App() {
  const player = new Player();
  return (
    <div className="App" onClick={() => player.initialize()} >
      <Chart notes={[...getChromaticScale("A0", "E9")]} player={player} />
	    <p>Old page is <a href="http://old.charmonics.milosz.ca">here</a>.</p>
    </div>
  );
}

export default App;
