import Note from "./Note";

export default class Player {
  private oscillatorNode!: OscillatorNode;
  private gainNode!: GainNode;
  private audioContext!: AudioContext;
  private isInitialized = false;

  initialize() {
    if (!this.isInitialized) {
      this.audioContext = new AudioContext();
      this.oscillatorNode = this.audioContext.createOscillator();
      this.oscillatorNode.type = "sine";
      this.gainNode = this.audioContext.createGain();
      this.gainNode.gain.setValueAtTime(0, this.audioContext.currentTime);
      this.oscillatorNode.connect(this.gainNode);
      this.gainNode.connect(this.audioContext.destination);
      this.oscillatorNode.start();
      this.isInitialized = true;
    }
  }

  play(note: Note) {
    if (this.isInitialized) {
      const currentTime = this.audioContext.currentTime;
      this.oscillatorNode.frequency.setValueAtTime(note.frequency, currentTime);
      this.gainNode.gain.setValueAtTime(1, currentTime);
    }
  }

  stop() {
    if (this.isInitialized) {
      this.gainNode.gain.setValueAtTime(0, this.audioContext.currentTime);
    }
  }
}

